import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import PassagemListagemItem from './PassagemListagemItem.react.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class PassagemListagem extends Component {
  static propTypes = {
    filter: PropTypes.string,
    page: PropTypes.object,
    searchWithPage: PropTypes.func,
    addErrorNotification: PropTypes.func.isRequired,
    passagemlistagem: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { showConfirmacao: false };
  }

  generateKey = passagem => {
    return `${passagem.get('id')}_
            ${passagem.get('exercicio')}`;
  };

  renderAdiantamentos = data => {
    return (
      <PassagemListagemItem
        key={this.generateKey(data)}
        passagem={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const { page, searchWithPage } = this.props;

    const adiantamentos = page.get('content');

    return (
      <Panel isForm tableResponsive expansible={false}>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={adiantamentos.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="numeroSolicitacao"
                  label="Nº Solicitação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataSolicitacao"
                  label="Data Solicitação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="servidor.nome"
                  label="Servidor"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="servidor.cargo"
                  label="Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="servidor.matricula"
                  label="Matrícula"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="destinoViagem"
                  label="Destino da Viagem"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="tipoPassagem"
                  label="Tipo de Passagem"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="empresaFornecedora.nome"
                  label="Empresa Fornecedora"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{adiantamentos.map(this.renderAdiantamentos)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(PassagemListagem);

import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  passagens: [],
  passagem: {},
  arquivos: [],
  filtros: [],
  tiposPassagem: []
});

function passagemReducer(state = initialState, action) {
  switch (action.type) {
    case types.PASSAGENS_LIST_START:
    case types.PASSAGEM_START:
    case types.ARQUIVOS_START:
    case types.TIPOS_PASSAGENS_START: {
      return startLoad(state);
    }

    case types.PASSAGENS_LIST_ERROR:
    case types.PASSAGEM_ERROR:
    case types.ARQUIVOS_ERROR:
    case types.TIPOS_PASSAGENS_ERROR: {
      return endLoad(state);
    }

    case types.PASSAGENS_LIST_SUCCESS: {
      return endLoad(state.set('passagens', fromJS(action.payload)));
    }
    case types.PASSAGEM_SUCCESS: {
      return endLoad(state.set('passagem', fromJS(action.payload)));
    }
    case types.ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.TIPOS_PASSAGENS_SUCCESS: {
      return endLoad(state.set('tiposPassagem', fromJS(action.payload)));
    }
    case types.RESET_CONSULTA_PASSAGENS: {
      return initialState;
    }
    default:
      return state;
  }
}

export default passagemReducer;

import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import PassagemDetalheInformacao from './PassagemDetalheInformacao.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';

export class FormPassagemDetail extends Component {
  static propTypes = {
    passagem: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findPassagem: PropTypes.func.isRequired,
    arquivos: PropTypes.object.isRequired,
    findArquivos: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findPassagem, findArquivos, location } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);
    const idAdiantamento = params.idAdiantamento;

    findPassagem(idAdiantamento);
    findArquivos(idAdiantamento);
  }

  render() {
    const { passagem, downloadArquivo, arquivos } = this.props;

    return (
      <div>
        <Helmet title={`Passagem - ${passagem.get('nomeServidor')}`} />

        <PageHeader location={this.props.location} />

        <PassagemDetalheInformacao passagem={passagem} />

        <div className="hidden-print">
          <Panel title="Anexos" isTable tableResponsive>
            <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
          </Panel>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    passagem: state.passagem.get('passagem'),
    arquivos: state.passagem.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormPassagemDetail);

import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class PassagemListagemItem extends Component {
  static propTypes = {
    passagem: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    const id = item.get('id');

    this.props.history.push(`passagens/detalhes?idAdiantamento=${id}`);
  };

  generateKey = passagem => {
    return `${passagem.get('id')}_
            ${passagem.get('exercicio')}`;
  };

  render() {
    const { passagem } = this.props;
    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={this.generateKey(passagem)}
        data={passagem}
        onMore={this.onViewDetalhes}
      >
        <td id="numeroSolicitacao">{passagem.get('numeroSolicitacao')} </td>
        <td id="dataSolicitacao">
          {DateUtils.formatDateTimeShort(passagem.get('dataSolicitacao'))}{' '}
        </td>
        <td id="nomeServidor">{passagem.get('nomeServidor')} </td>
        <td id="cargoServidor">{passagem.get('cargoServidor')} </td>
        <td id="matriculaServidor">{passagem.get('matriculaServidor')} </td>
        <td id="destinoViagem">
          {passagem.get('destinoViagem')} - {passagem.get('uf')}
        </td>
        <td id="tipoPassagem">{passagem.get('tipoPassagem')} </td>
        <td id="nomeEmpresaFornecedora">
          {passagem.get('nomeEmpresaFornecedora')}
        </td>
      </TableActions>
    );
  }
}

export default PassagemListagemItem;

export const REEMBOLSOS = 'REEMBOLSOS';
export const REEMBOLSOS_START = 'REEMBOLSOS_START';
export const REEMBOLSOS_SUCCESS = 'REEMBOLSOS_SUCCESS';
export const REEMBOLSOS_ERROR = 'REEMBOLSOS_ERROR';

export const REEMBOLSO = 'REEMBOLSO';
export const REEMBOLSO_START = 'REEMBOLSO_START';
export const REEMBOLSO_SUCCESS = 'REEMBOLSO_SUCCESS';
export const REEMBOLSO_ERROR = 'REEMBOLSO_ERROR';

export const CONSULTA_REEMBOLSOS = 'CONSULTA_REEMBOLSOS';
export const CONSULTA_REEMBOLSOS_START = 'CONSULTA_REEMBOLSOS_START';
export const CONSULTA_REEMBOLSOS_ERROR = 'CONSULTA_REEMBOLSOS_ERROR';
export const CONSULTA_REEMBOLSOS_SUCCESS = 'CONSULTA_REEMBOLSOS_SUCCESS';

export const ARQUIVOS = 'ARQUIVOS';
export const ARQUIVOS_START = 'ARQUIVOS_START';
export const ARQUIVOS_SUCCESS = 'ARQUIVOS_SUCCESS';
export const ARQUIVOS_ERROR = 'ARQUIVOS_ERROR';

export const TIPO_REEMBOLSO = 'TIPO_REEMBOLSO';
export const TIPO_REEMBOLSO_START = 'TIPO_REEMBOLSO_START';
export const TIPO_REEMBOLSO_SUCCESS = 'TIPO_REEMBOLSO_SUCCESS';
export const TIPO_REEMBOLSO_ERROR = 'TIPO_REEMBOLSO_ERROR';

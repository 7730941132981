export const PASSAGENS_LIST = 'PASSAGENS_LIST';
export const PASSAGENS_LIST_START = 'PASSAGENS_LIST_START';
export const PASSAGENS_LIST_SUCCESS = 'PASSAGENS_LIST_SUCCESS';
export const PASSAGENS_LIST_ERROR = 'PASSAGENS_LIST_ERROR';

export const RESET_CONSULTA_PASSAGENS = 'RESET_CONSULTA_PASSAGENS';

export const PASSAGEM = 'PASSAGEM';
export const PASSAGEM_START = 'PASSAGEM_START';
export const PASSAGEM_SUCCESS = 'PASSAGEM_SUCCESS';
export const PASSAGEM_ERROR = 'PASSAGEM_ERROR';

export const ARQUIVOS = 'ARQUIVOS';
export const ARQUIVOS_START = 'ARQUIVOS_START';
export const ARQUIVOS_SUCCESS = 'ARQUIVOS_SUCCESS';
export const ARQUIVOS_ERROR = 'ARQUIVOS_ERROR';

export const TIPOS_PASSAGENS = 'TIPOS_PASSAGENS';
export const TIPOS_PASSAGENS_START = 'TIPOS_PASSAGENS_START';
export const TIPOS_PASSAGENS_SUCCESS = 'TIPOS_PASSAGENS_SUCCESS';
export const TIPOS_PASSAGENS_ERROR = 'TIPOS_PASSAGENS_ERROR';

import * as types from './Types.js';

export function findPassagens(filter) {
  return ({ fetch }) => ({
    type: types.PASSAGENS_LIST,
    payload: {
      promise: fetch(`/api/passagens?${filter}`)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_PASSAGENS,
    payload: {}
  };
}

export function findPassagem(idAdiantamento) {
  return ({ fetch }) => ({
    type: types.PASSAGEM,
    payload: {
      promise: fetch(`/api/passagens/detail?idAdiantamento=${idAdiantamento}`)
    }
  });
}

export function findArquivos(idAdiantamento) {
  return ({ fetch }) => ({
    type: types.ARQUIVOS,
    payload: {
      promise: fetch(`/api/passagens/arquivos?idAdiantamento=${idAdiantamento}`)
    }
  });
}

export function getTiposPassagem() {
  return ({ fetch }) => ({
    type: types.TIPOS_PASSAGENS,
    payload: {
      promise: fetch('/api/passagens/tipos-passagem')
    }
  });
}

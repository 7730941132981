import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import ReembolsoListagem from './ReembolsoListagem.react.js';

export class FormReembolso extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findReembolsos: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    tipoSolicitacao: PropTypes.object.isRequired,
    findTiposReembolso: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.carregarReembolsos();
  }

  carregarReembolsos = () => {
    const { page, findTiposReembolso } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
    findTiposReembolso();
  };

  consultarReembolsos = event => {
    this.carregarReembolsos();
    event && event.preventDefault();
    this.refs.panelFiltroReembolsos &&
      this.refs.panelFiltroReembolsos.collapse();
  };

  onResetFiltro = () => {
    const { resetCrud, findReembolsos } = this.props;
    resetCrud('filtroReembolsos');
    findReembolsos('');
  };

  searchWithPage = page => {
    const { findReembolsos } = this.props;

    const filtro = this.montaFiltro(page);

    findReembolsos(filtro);
  };

  montaFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    const { filtros } = this.props;

    if (filtros.get('servidor')) {
      filter = addFilterUrl(filter, `servidor=${filtros.get('servidor')}`);
    }

    if (filtros.get('destino')) {
      filter = addFilterUrl(filter, `destino=${filtros.get('destino')}`);
    }

    if (filtros.get('empresaFornecedora')) {
      filter = addFilterUrl(
        filter,
        `empresaFornecedora=${filtros.get('empresaFornecedora')}`
      );
    }

    if (filtros.get('tipoSolicitacao')) {
      filter = addFilterUrl(
        filter,
        `tipoSolicitacao=${filtros.get('tipoSolicitacao')}`
      );
    }

    return addPagination(page, filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroReembolsos', event);
  };

  renderTipoSolicitacao = data => {
    return (
      <option key={data.get('codigo')} value={data.get('codigo')}>
        {data.get('codigo') + ' - ' + data.get('descricao')}
      </option>
    );
  };

  render() {
    const { page, filtros, tipoSolicitacao } = this.props;

    const filter = this.montaFiltro(page);
    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <Panel
          title="Consultar em Reembolso / Ressarcimento"
          mobileClose
          isForm
          ref="panelFiltroReembolsos"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={5}>
                <Input
                  id="servidor"
                  ref="servidor"
                  name="servidor"
                  placeholder="Nome do Servidor"
                  label="Nome do Servidor"
                  type="text"
                  value={filtros.get('servidor')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={5}>
                <Input
                  id="destino"
                  ref="destino"
                  name="destino"
                  placeholder="Destino de Viagem"
                  label="Destino de Viagem"
                  type="text"
                  value={filtros.get('destino')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={5}>
                <Input
                  id="empresaFornecedora"
                  ref="empresaFornecedora"
                  name="empresaFornecedora"
                  placeholder="Empresa Fornecedora"
                  label="Empresa Fornecedora"
                  type="text"
                  value={filtros.get('empresaFornecedora')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={6} sm={4} md={4}>
                <Input
                  id="tipoSolicitacao"
                  name="tipoSolicitacao"
                  ref="tipoSolicitacao"
                  placeholder="Tipo da Solicitação"
                  label="Tipo da Solicitação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoSolicitacao')}
                >
                  <option value="">Selecione</option>
                  {tipoSolicitacao &&
                    tipoSolicitacao.map(this.renderTipoSolicitacao)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.consultarReembolsos}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <ReembolsoListagem
          filter={filter}
          page={page}
          history={this.props.history}
          searchWithPage={this.searchWithPage}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroReembolsos', 'currentRecord'], Map({})),
    page: state.reembolso.get('reembolsos'),
    tipoSolicitacao: state.reembolso.get('tipoSolicitacao')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormReembolso);

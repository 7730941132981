import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import ReembolsoListagemItem from './ReembolsoListagemItem.react.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class ReembolsoListagem extends Component {
  static propTypes = {
    filter: PropTypes.string,
    page: PropTypes.object,
    searchWithPage: PropTypes.func,
    reembolsolistagem: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { showConfirmacao: false };
  }

  generateKey = reembolso => {
    return `${reembolso.get('entidade')}_
            ${reembolso.get('exercicio')}_
            ${reembolso.get('reembolsoId')}`;
  };

  renderReembolsos = data => {
    return (
      <ReembolsoListagemItem
        key={this.generateKey(data)}
        reembolso={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const { page, searchWithPage } = this.props;

    const reembolsos = page.get('content');

    return (
      <Panel isForm tableResponsive expansible={false}>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacaoByTable
              modulo="3"
              table="reembolso"
              column="dataultimaatualizacao"
            />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={reembolsos.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="numeroSolicitacao"
                  label="Numero Solicitação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="servidor"
                  label="Servidor"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataSaida"
                  label="Data de Saída"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataRetorno"
                  label="Data de Retorno"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="destino"
                  label="Destino da Viagem"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataSolicitacao"
                  label="Data da Solicitação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="empresaFornecedora"
                  label="Empresa Fornecedora"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="cnpjCpfEmpressaFornecedora"
                  label="CNPJ/CPF"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valor"
                  label="Valor do Reembolso/Ressarcimento"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{reembolsos.map(this.renderReembolsos)}</tbody>
            {!reembolsos.isEmpty && (
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(ReembolsoListagem);

import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  reembolsos: [],
  reembolso: {},
  filtros: [],
  arquivos: [],
  totalAdiantamentos: []
});

function reembolsoReducer(state = initialState, action) {
  switch (action.type) {
    case types.REEMBOLSOS_START:
    case types.CONSULTA_REEMBOLSOS_START:
    case types.REEMBOLSO_START:
    case types.TIPO_REEMBOLSO_START:
    case types.ARQUIVOS_START: {
      return startLoad(state);
    }

    case types.REEMBOLSOS_ERROR:
    case types.CONSULTA_REEMBOLSOS_ERROR:
    case types.REEMBOLSO_ERROR:
    case types.TIPO_REEMBOLSO_ERROR:
    case types.ARQUIVOS_ERROR: {
      return endLoad(state);
    }

    case types.REEMBOLSOS_SUCCESS:
    case types.CONSULTA_REEMBOLSOS_SUCCESS: {
      return endLoad(state.set('reembolsos', fromJS(action.payload)));
    }
    case types.REEMBOLSO_SUCCESS: {
      return endLoad(state.set('reembolso', fromJS(action.payload)));
    }
    case types.ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.TIPO_REEMBOLSO_SUCCESS: {
      return endLoad(state.set('tipoSolicitacao', fromJS(action.payload)));
    }

    default:
      return state;
  }
}

export default reembolsoReducer;

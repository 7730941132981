import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ReembolsoListagemItem extends Component {
  static propTypes = {
    reembolso: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    const reembolsoId = item.get('reembolsoId');
    this.props.history.push(`reembolsos/detalhes?reembolsoId=${reembolsoId}`);
  };

  generateKey = reembolso => {
    return `${reembolso.get('entidade')}_
            ${reembolso.get('exercicio')}_
            ${reembolso.get('reembolsoId')}`;
  };

  render() {
    const { reembolso } = this.props;
    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={this.generateKey(reembolso)}
        data={reembolso}
        onMore={this.onViewDetalhes}
      >
        <td id="listNumeroSolicitacao">
          {reembolso.get('numeroSolicitacao')}{' '}
        </td>
        <td id="listServidor">{reembolso.get('servidor')} </td>
        <td id="listDataSaida">
          {DateUtils.formatDateTimeShort(reembolso.get('dataSaida'))}{' '}
        </td>
        <td id="listDataRetorno">
          {DateUtils.formatDateTimeShort(reembolso.get('dataRetorno'))}{' '}
        </td>
        <td id="listDestino">{reembolso.get('destino')} </td>
        <td id="listDataSolicitacao">
          {DateUtils.formatDateTimeShort(reembolso.get('dataSolicitacao'))}{' '}
        </td>
        <td id="listEmpresaFornecedora">
          {reembolso.get('empresaFornecedora')}{' '}
        </td>
        <td id="listCnpjCpfEmpressaFornecedora">
          {reembolso.get('cnpjCpfEmpressaFornecedora')}{' '}
        </td>
        <td id="listValor">
          <NumberFormatter
            id="listValorExcedido"
            value={reembolso.get('valor')}
          />
        </td>
      </TableActions>
    );
  }
}

export default ReembolsoListagemItem;

import * as types from './Types.js';

export function findReembolsos(filter) {
  return ({ fetch }) => ({
    type: types.REEMBOLSOS,
    payload: {
      promise: fetch(`/api/reembolsos?${filter}`)
    }
  });
}

export function findReembolsoDetail(reembolsoId) {
  return ({ fetch }) => ({
    type: types.REEMBOLSO,
    payload: {
      promise: fetch(`/api/reembolsos/detail?reembolsoId=${reembolsoId}`)
    }
  });
}

export function findArquivos(reembolsoId) {
  return ({ fetch }) => ({
    type: types.ARQUIVOS,
    payload: {
      promise: fetch(`/api/reembolsos/arquivos?reembolsoId=${reembolsoId}`)
    }
  });
}

export function findTiposReembolso() {
  return ({ fetch }) => ({
    type: types.TIPO_REEMBOLSO,
    payload: {
      promise: fetch(`/api/reembolsos/tipos-reembolso`)
    }
  });
}

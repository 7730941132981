import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import { PassagemListagem } from './PassagemListagem.react';

export class FormPassagens extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findPassagens: PropTypes.func.isRequired,
    getTiposPassagem: PropTypes.func.isRequired,
    tiposPassagem: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { getTiposPassagem } = this.props;

    this.carregarPassagens();
    getTiposPassagem();
  }

  carregarPassagens = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  consultarPassagens = event => {
    this.carregarPassagens();
    event && event.preventDefault();
    this.refs.panelFiltroPassagens && this.refs.panelFiltroPassagens.collapse();
  };

  onResetFiltro = () => {
    const { resetCrud, findPassagens } = this.props;
    resetCrud('filtroPassagens');
    findPassagens('');
  };

  searchWithPage = page => {
    const { findPassagens } = this.props;

    const filtro = this.montaFiltro(page);

    findPassagens(filtro);
  };

  montaFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    const { filtros } = this.props;

    if (filtros.get('nomeServidor')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nomeServidor')}`);
    }

    if (filtros.get('destinoViagem')) {
      filter = addFilterUrl(filter, `destino=${filtros.get('destinoViagem')}`);
    }

    if (filtros.get('tipoPassagem')) {
      filter = addFilterUrl(
        filter,
        `idTipoPassagem=${filtros.get('tipoPassagem')}`
      );
    }

    if (filtros.get('nomeEmpresaFornecedora')) {
      filter = addFilterUrl(
        filter,
        `nomeFornecedor=${filtros.get('nomeEmpresaFornecedora')}`
      );
    }

    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    return addPagination(page, filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroPassagens', event);
  };

  renderTiposPassagem = data => {
    return (
      <option key={data.get('codigo')} value={data.get('codigo')}>
        {data.get('descricao')}
      </option>
    );
  };

  render() {
    const { page, filtros, tiposPassagem } = this.props;

    const filter = this.montaFiltro(page);

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Passagens"
          mobileClose
          isForm
          ref="panelFiltroPassagens"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={5}>
                <Input
                  id="nomeServidor"
                  ref="nomeServidor"
                  name="nomeServidor"
                  placeholder="Nome do Servidor"
                  label="Nome do Servidor"
                  type="text"
                  value={filtros.get('nomeServidor')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={6} sm={2} md={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={6} sm={2} md={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>

              <Col xs={12} sm={4} md={3}>
                <Input
                  id="tipoPassagem"
                  name="tipoPassagem"
                  ref="tipoPassagem"
                  label="Tipo de Passagem"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoPassagem')}
                >
                  <option value="">Selecione</option>
                  {tiposPassagem.map(this.renderTiposPassagem)}
                </Input>
              </Col>

              <Col xs={12} sm={4} md={4}>
                <Input
                  id="nomeEmpresaFornecedora"
                  ref="nomeEmpresaFornecedora"
                  name="nomeEmpresaFornecedora"
                  placeholder="Empresa Fornecedora"
                  label="Empresa Fornecedora"
                  type="text"
                  value={filtros.get('nomeEmpresaFornecedora')}
                  onChange={this.onChangeFiltro}
                />
              </Col>

              <Col xs={12} sm={4} md={5}>
                <Input
                  id="destinoViagem"
                  ref="destinoViagem"
                  name="destinoViagem"
                  placeholder="Destino"
                  label="Destino"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('destinoViagem')}
                />
              </Col>

              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.consultarPassagens}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <PassagemListagem
          filter={filter}
          page={page}
          history={this.props.history}
          searchWithPage={this.searchWithPage}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroPassagens', 'currentRecord'], Map({})),
    page: state.passagem.get('passagens'),
    tiposPassagem: state.passagem.get('tiposPassagem')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormPassagens);

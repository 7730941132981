import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import ReembolsoDetalheAnexos from './ReembolsoDetalheAnexos.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
export class ReembolsoDetalheInformacao extends Component {
  static propTypes = {
    reembolso: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  render() {
    const { reembolso, downloadArquivo } = this.props;

    return (
      <div>
        {reembolso && (
          <section>
            <CabecalhoEntidade />

            <BotaoImprimir />

            <Panel title="Detalhes do Reembolso/Reembolso">
              <DisplayData border>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={4}
                    title="Número Solicitação"
                    value={reembolso.get('numeroSolicitacao')}
                    id="numeroSolicitacao"
                  />
                  <DisplayDataItem
                    sm={4}
                    title="Tipo Solicitação"
                    value={reembolso.get('tipoSolicitacao')}
                    id="tipoSolicitacao"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Data De Solicitação"
                    value={DateUtils.formatDateTimeShort(
                      reembolso.get('dataSolicitacao')
                    )}
                    id="dataSolicitacao"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={4}
                    title="Servidor"
                    value={reembolso.get('servidor')}
                    id="servidor"
                  />
                  <DisplayDataItem
                    sm={4}
                    title="Cargo"
                    value={reembolso.get('cargo')}
                    id="cargo"
                  />
                  <DisplayDataItem
                    sm={4}
                    title="Matricula"
                    value={reembolso.get('matricula')}
                    id="matricula"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={6}
                    title="Dotação"
                    value={reembolso.get('dotacao')}
                    id="dotacao"
                  />
                  <DisplayDataItem
                    sm={6}
                    title="Valor"
                    value={NumberUtils.format(reembolso.get('valor'))}
                    id="valor"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={6}
                    title="Empresa Fornecedora"
                    value={reembolso.get('empresaFornecedora')}
                    id="empresaFornecedora"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Data De Saída"
                    value={DateUtils.formatDateTimeShort(
                      reembolso.get('dataSaida')
                    )}
                    id="dataSaida"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Data De Retorno"
                    value={DateUtils.formatDateTimeShort(
                      reembolso.get('dataRetorno')
                    )}
                    id="dataRetorno"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={6}
                    title="Destino da Viagem"
                    value={reembolso.get('destino')}
                    id="destino"
                  />
                  <DisplayDataItem
                    sm={6}
                    title="Meio de Transporte"
                    value={reembolso.get('transporte')}
                    id="transporte"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={6}
                    title="Justificativa"
                    value={reembolso.get('justificativa')}
                    id="justificativa"
                  />
                </DisplayDataRow>
              </DisplayData>

              <hr className="mt-xs mb-xs" />

              {!!reembolso.get('anexos')?.length && (
                <Tabs id="tabs">
                  <Tab onlyTable tableResponsive id="anexos" title="Anexos">
                    {reembolso.get('anexos') && (
                      <ReembolsoDetalheAnexos
                        anexos={reembolso.get('anexos')}
                        downloadArquivo={downloadArquivo}
                      />
                    )}
                  </Tab>
                </Tabs>
              )}
            </Panel>
          </section>
        )}
      </div>
    );
  }
}

export default ReembolsoDetalheInformacao;

import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';

export class PassagemDetalheInformacao extends Component {
  static propTypes = {
    passagem: PropTypes.object.isRequired
  };

  render() {
    const { passagem } = this.props;

    return (
      <section>
        <CabecalhoEntidade />

        <BotaoImprimir />

        <Panel title="Detalhes da Passagem">
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Nº Solicitação"
                value={passagem.get('numeroSolicitacao')}
                id="solicitacao"
              />

              <DisplayDataItem
                sm={3}
                title="Data Solicitação"
                value={DateUtils.formatDateTimeShort(
                  passagem.get('dataSolicitacao')
                )}
                id="dataSolicitacao"
              />

              <DisplayDataItem
                sm={6}
                title="Valor do Adiantamento"
                value={NumberUtils.format(passagem.get('valorAdiantamento'))}
                id="valorAdiantamento"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Nome Servidor"
                value={passagem.get('nomeServidor')}
                id="nome"
              />
              <DisplayDataItem
                sm={4}
                title="Cargo"
                value={passagem.get('cargoServidor')}
                id="cargo"
              />
              <DisplayDataItem
                sm={2}
                title="Matrícula"
                value={passagem.get('matriculaServidor')}
                id="matricula"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Empresa Fornecedora"
                value={passagem.get('nomeEmpresaFornecedora')}
                id="nomeEmpresaFornecedora"
              />
              <DisplayDataItem
                sm={4}
                title="Destino / UF"
                value={
                  passagem.get('destinoViagem')
                    ? passagem.get('destinoViagem') + ' / ' + passagem.get('uf')
                    : ''
                }
                id="destinoViagem"
              />
              <DisplayDataItem
                sm={3}
                title="Tipo de Passagem"
                value={passagem.get('tipoPassagem')}
                id="tipoPassagem"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Data Início"
                value={DateUtils.formatDateTimeShort(
                  passagem.get('dataInicio')
                )}
                id="dataInicio"
              />

              <DisplayDataItem
                sm={3}
                title="Data Fim"
                value={DateUtils.formatDateTimeShort(passagem.get('dataFim'))}
                id="dataFim"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Justificativa"
                value={passagem.get('justificativa')}
                id="justificativa"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {}

export default connect(mapStateToProps, {})(PassagemDetalheInformacao);

import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  getAllValuesFromUrlSearchParams,
  getValueFromUrlSearchParams
} from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import ReembolsoDetalheInformacao from './ReembolsoDetalheInformacao.react.js';

export class FormReembolsoDetail extends Component {
  static propTypes = {
    reembolso: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findReembolsoDetail: PropTypes.func.isRequired,
    arquivos: PropTypes.object.isRequired,
    findArquivos: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findReembolsoDetail, findArquivos } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    findReembolsoDetail(params.reembolsoId);
    findArquivos(params.reembolsoId);
  }

  render() {
    const { reembolso, downloadArquivo, arquivos } = this.props;

    return (
      <section>
        <Helmet
          id="helmet"
          title={'Reembolso / Reembolso - ' + reembolso.get('servidor')}
        />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Reembolso / Reembolso: ${reembolso.get('servidor')}`}
        />

        <ReembolsoDetalheInformacao
          reembolso={reembolso}
          downloadArquivo={downloadArquivo}
        />

        <div className="hidden-print">
          <form>
            <Panel isTable tableResponsive expansible={false} title="Anexos">
              <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
            </Panel>
          </form>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    reembolso: state.reembolso.get('reembolso'),
    arquivos: state.reembolso.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormReembolsoDetail);

import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

import { getStartDate } from './ActionsDiario.js';

import FilterButtons from '../components/FilterButtons.react';

export class DiarioFiltro extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClearFilter: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    mes: PropTypes.number,
    findDiarios: PropTypes.func.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    getStartDate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.meses = [
      { nome: 'Janeiro', value: 1 },
      { nome: 'Fevereiro', value: 2 },
      { nome: 'Março', value: 3 },
      { nome: 'Abril', value: 4 },
      { nome: 'Maio', value: 5 },
      { nome: 'Junho', value: 6 },
      { nome: 'Julho', value: 7 },
      { nome: 'Agosto', value: 8 },
      { nome: 'Setembro', value: 9 },
      { nome: 'Outubro', value: 10 },
      { nome: 'Novembro', value: 11 },
      { nome: 'Dezembro', value: 12 }
    ];

    this.state = {
      anos: [],
      lastLoadedYear: new Date().getFullYear(),
    };
  }

  componentDidMount() {
    this.loadStartDateAndYears();
  }

  loadStartDateAndYears = async () => {
    const { getStartDate } = this.props;

    try {

      const response = await getStartDate();

      const startYear = response.value; 

      this.setState({
        anos: this.getAllYears(startYear),
      });
    } catch (error) {
      console.error("Erro ao buscar o ano inicial", error);
    }
  };

  getAllYears = (startYear) => {
    let years = [];
    for (let i = new Date().getFullYear(); i >=startYear; i--) {
      years.push(i);
    }
    return years;
  };

  renderMeses = (mesPublicacao) => (
    <option key={mesPublicacao.value} value={mesPublicacao.value}>
      {mesPublicacao.nome}
    </option>
  );

  renderAnos = (anoPublicacao) => (
    <option key={anoPublicacao} value={anoPublicacao}>
      {anoPublicacao}
    </option>
  );

  onConsultarDiarios = () => {
    const { findDiarios, filter, addErrorNotification } = this.props;
    let { dataInicio, dataFim, chave } = filter;

    chave = chave.replace(/"/g, '');
    filter.chave = encodeURIComponent(chave);

    if (dataInicio) {
      const dateInicio = new Date(dataInicio);
      const currentYear = new Date().getFullYear();

      if (
        isNaN(dateInicio.getTime()) ||
        dateInicio.getFullYear() < 1900 ||
        dateInicio.getFullYear() > currentYear
      ) {
        addErrorNotification('Data de Início Inválida', {
          message:
            'A data de início é inválida. Por favor, insira uma data entre 1900 e o ano atual.',
          modal: true
        });
        return;
      }
    }

    if (dataFim) {
      const dateFim = new Date(dataFim);
      const currentYear = new Date().getFullYear();

      if (
        isNaN(dateFim.getTime()) ||
        dateFim.getFullYear() < 1900 ||
        dateFim.getFullYear() > currentYear
      ) {
        addErrorNotification('Data de Fim Inválida', {
          message:
            'A data de fim é inválida. Por favor, insira uma data entre 1900 e o ano atual.',
          modal: true
        });
        return;
      }
    }

    findDiarios(filter);
  };

  onChange = (event) => {
    const { onChangeFilter } = this.props;
    const name = event.target.name;
    let value = event.target.value;

    onChangeFilter(name, value);
  };

  render() {
    const { show, onClearFilter } = this.props;
    const { mesPublicacao, chave, anoPublicacao, numero, dataInicio, dataFim } = this.props.filter;
    const { anos } = this.state;

    if (!show) {
      return null;
    }

    return (
      <React.Fragment>
        <Panel title="Consultar em Diários" mobileClose isForm>
          <Row>
            <Col xs={3} sm={3} md={3}>
              <Input
                name="dataInicio"
                label="Data Início"
                type="date"
                onChange={this.onChange}
                value={dataInicio}
              />
            </Col>
            <Col xs={3} sm={3} md={3}>
              <Input
                name="dataFim"
                label="Data Fim"
                type="date"
                onChange={this.onChange}
                value={dataFim}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={2} sm={2} md={2}>
              <Input
                name="mesPublicacao"
                label="Mês"
                type="select"
                value={mesPublicacao}
                onChange={this.onChange}
              >
                <option value="">Selecione</option>
                {this.meses.map(this.renderMeses)}
              </Input>
            </Col>
            <Col xs={2} sm={2} md={2}>
              <Input
                name="anoPublicacao"
                label="Ano"
                type="select"
                value={anoPublicacao}
                onChange={this.onChange}
              >
                <option value="">Selecione</option>
                {anos.map(this.renderAnos)}
              </Input>
            </Col>
            <Col xs={2} sm={2} md={2}>
              <Input
                name="numero"
                label="Edição"
                type="number"
                onChange={this.onChange}
                value={numero}
              />
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Input
                name="chave"
                label="Palavra chave"
                type="text"
                value={chave}
                onChange={this.onChange}
              />
            </Col>
            <Col xs={12} sm={4} md={4}>
              <FilterButtons
                idBtnReset="btnResetFilter"
                onConsulta={this.onConsultarDiarios}
                onReset={onClearFilter}
              />
            </Col>
          </Row>
        </Panel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    anos: state.diarioReducer.get('dataInicio'), 
  };
}

const allActions = {
  addErrorNotification,
   getStartDate 
};


export default connect(mapStateToProps, allActions)(DiarioFiltro);
